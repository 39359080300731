<template>
<div class="section">

    <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
        <div class="card popupDialog">
            <div class="card-content">
                    <iframe id="pdf" class="pdfiFrame">
                        Your browser doesn't support iframes
                    </iframe> 
            </div>
        </div>
    </b-modal>        

</div>

</template>

<script>

import { PDFDocument, StandardFonts, PageSizes, degrees, grayscale, rgb } from 'pdf-lib'
// import { PageSizes } from 'pdf-lib'
// import { degrees } from 'pdf-lib'
// import { rgb } from 'pdf-lib'
import moment from 'moment'
import UserStore from "../../../store/UserStore"
// import { text } from 'body-parser'

export default {
    props: {
        isShowPDFDialog: Boolean,
        fetchStatus: String,
        profitAndLossData: {},
        summaryNumbers: {},
        deptSales: [],
        fuelDeliveredByType: [],
        inventoryPurchase: [],
        totalFuelVolumePurchased: Number,
        totalFuelPurchase: Number,
        totalPaymentMethods: {},
        lotterySales: {},
        dateRange: []
    },

    data() {
        return {
            pdfDoc: {},
            helveticaFont: {},
            pageNumber: 0,
            page: {},
            sortedRecords: [],
            isShowPDFDialogLocal: false,
            deptMappingsRevenue: [],
            totalBookValue: 0,
            totalBookCount: 0,
            totalSoldValue: 0,
            totalBalanceValue: 0,
            totalFuelSales: 0,
            totalMerchSales: 0,
            totalOtherIncome: 0,
            totalRevenue: 0,
            totalFuelCost: 0,
            totalMerchCost: 0,
            totalCostOfGoodsSold: 0,
            otherIncomeGroupedByVendor: [],
            totalSaleVolume: 0,
            fuelInventoryPurchase: 0,
            dollarUSLocale: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'})
        }

    },

    methods: {

        closePDFDialog() {
            this.$emit('close-pdf-dialog')
        },

        createPdf: async(gthis) => {

            gthis.pdfDoc = await PDFDocument.create()
            // const pdfDoc = gthis.pdfDoc
            gthis.pdfDoc.setAuthor('VEECLi Inc. All rights reserved.')
            gthis.pdfDoc.setTitle('Revenue Summary')
            gthis.pdfDoc.setSubject('support@veecli.com')
            gthis.pdfDoc.setCreator('VEECLi Inc - www.veecli.com')
            gthis.pdfDoc.setProducer('VEECLi Backoffice Software')

            gthis.helveticaFont = await gthis.pdfDoc.embedFont(StandardFonts.Helvetica)

            await gthis.pageHeader(gthis)
            var text = ""
            var row = 680


            // Merchandise Sales
            if (gthis.getSetting('accountantShowSales')) {
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'MERCHANDISE SALES')

                row = await gthis.getNextRow(gthis,row)
                gthis.drawColHeadRight(gthis, row, 500, 9, "Sales Amount")

                var totalMerchSales = 0.00
                for (const dept of gthis.deptSales) {
                    
                    row = await gthis.drawRowTextLeft(gthis, row, dept.storeMapping[0].toUpperCase() + dept.storeMapping.substr(1).toLowerCase())


                    gthis.drawTextRightCurrency(gthis, row, 500, 10, dept.netSalesValue)
                    totalMerchSales += dept.netSalesValue

                    row = await gthis.drawLine(gthis, row)
                }

                row = await gthis.getNextRow(gthis,row, 3)

                gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL MERCHANDISE SALES')

                gthis.drawTextRightCurrency(gthis, row, 570, 10, totalMerchSales)
                gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            }
            // End Merchandise Sales

            // Start Fuel Sales
            row = await gthis.getNextRow(gthis,row, 6)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'FUEL SALES')

            row = await gthis.getNextRow(gthis,row)
            gthis.drawColHeadRight(gthis, row, 320, 9, "Price/Gal")
            gthis.drawColHeadRight(gthis, row, 420, 9, "Gallons Sold")
            gthis.drawColHeadRight(gthis, row, 500, 9, "Sales Amount")

            var totalFuelSales = 0.00
            var totalFuelVol = 0.00
            for (const fuel of gthis.profitAndLossData.fuelSalesList) {
                
                row = await gthis.drawRowTextLeft(gthis, row, fuel.product[0].toUpperCase() + fuel.product.substr(1).toLowerCase())

                text =  (fuel.saleAmount / fuel.saleVolume).toFixed(2) 
                gthis.drawTextRightCurrency(gthis, row, 320, 9, text)

                gthis.drawTextRight(gthis, row, 420, 10, fuel.saleVolume.toLocaleString('en-US'))

                gthis.drawTextRightCurrency(gthis, row, 500, 10, fuel.saleAmount)
                totalFuelSales += fuel.saleAmount
                totalFuelVol += fuel.saleVolume

                row = await gthis.drawLine(gthis, row)
            }

            row = await gthis.getNextRow(gthis,row, 3)

            gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL FUEL SALES')

            text =  (totalFuelSales / totalFuelVol).toFixed(2) 
            gthis.drawTextRightCurrency(gthis, row, 320, 9, text)

            gthis.drawTextRight(gthis, row, 420, 10, totalFuelVol.toLocaleString('en-US'))

            gthis.drawTextRightCurrency(gthis, row, 570, 10, totalFuelSales)
            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            // End Fuel Sales

            // Tax Info
            row = await gthis.getNextRow(gthis,row, 5)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'MERCHANDISE SALES & SALES TAX')

            row = await gthis.getNextRow(gthis,row)
            
             totalMerchSales = 0.00

            gthis.deptMappingsRevenue = gthis.profitAndLossData.deptRevenueAndCostList.filter((dept) => {
                return dept.storeMapping !== 'Taxes'
            })

            gthis.deptMappingsRevenue.forEach(mapping => {
                totalMerchSales += mapping.netSalesValue
            })

            row = await gthis.getNextRow(gthis,row)
            gthis.drawColHeadRight(gthis, row, 220, 9, "Current Rate")
            gthis.drawColHeadRight(gthis, row, 320, 9, "Tax Collected")
            gthis.drawColHeadRight(gthis, row, 420, 9, "Average Tax Rate")
            gthis.drawColHeadRight(gthis, row, 500, 9, "Sales Amount")

            var totalMerchTax = 0.00
            for (const tax of gthis.profitAndLossData.taxInfoList) {
                
                row = await gthis.drawRowTextLeft(gthis, row, tax.name.toLowerCase())

                text = tax.taxRate.toFixed(2) + "%"
                gthis.drawTextRight(gthis, row, 220, 10, text)

                gthis.drawTextRightCurrency(gthis, row, 320, 10, tax.netTax)

                text = "0.00"
                if ((tax.taxableSales + tax.taxableRefunds) > 0) {
                    text = ((tax.netTax * 100) / (tax.taxableSales + tax.taxableRefunds)).toFixed(2) + "%"
                }

                gthis.drawTextRight(gthis, row, 420, 9, text)

                gthis.drawTextRightCurrency(gthis, row, 500, 10, (tax.taxableSales + tax.taxableRefunds))
                totalMerchTax += tax.netTax

                row = await gthis.drawLine(gthis, row)
            }

            // row = await gthis.getNextRow(gthis,row, 3)

            var totalTaxableSales = 0
            if (gthis.profitAndLossData.taxInfoList !== undefined) {
                totalTaxableSales = gthis.profitAndLossData.taxInfoList.reduce((a, b) => {
                    return a + b["taxableSales"] + b["taxableRefunds"];
                }, 0);
            }
            row = await gthis.drawRowTextLeft(gthis, row, "No Tax")
            gthis.drawTextRightCurrency(gthis, row, 500, 10, (totalMerchSales - totalTaxableSales ))
            row = await gthis.drawLine(gthis, row)
            
            row = await gthis.getNextRow(gthis,row, 3)
            gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL MERCHANDISE TAX & SALES')

            gthis.drawTextRightCurrency(gthis, row, 320, 10, totalMerchTax)

            gthis.drawTextRightCurrency(gthis, row, 570, 10, totalMerchSales)
            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)

            // Payment Details

            if (gthis.getSetting('accountantShowPayment')) {
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'PAYMENT DETAILS')
                gthis.drawColHeadRight(gthis, row, 500, 9, "Sales Amount")
                row = await gthis.getNextRow(gthis,row, 1)
                row = await gthis.drawRowTextLeft(gthis, row, "Credit Cards")
                gthis.drawTextRightCurrency(gthis, row, 500, 10, gthis.totalPaymentMethods.creditAmount)
                row = await gthis.drawLine(gthis, row)

                row = await gthis.drawRowTextLeft(gthis, row, "Debit Cards")
                gthis.drawTextRightCurrency(gthis, row, 500, 10, gthis.totalPaymentMethods.debitAmount)
                row = await gthis.drawLine(gthis, row)

                row = await gthis.drawRowTextLeft(gthis, row, "EBT")
                gthis.drawTextRightCurrency(gthis, row, 500, 10, gthis.totalPaymentMethods.ebtAmount)
                row = await gthis.drawLine(gthis, row)

                row = await gthis.drawRowTextLeft(gthis, row, "Cash")
                gthis.drawTextRightCurrency(gthis, row, 500, 10, gthis.totalPaymentMethods.cashAmount)
                row = await gthis.drawLine(gthis, row)
                
                if (gthis.totalPaymentMethods.otherPayment != 0.00) {
                    row = await gthis.drawRowTextLeft(gthis, row, "Other")
                    gthis.drawTextRightCurrency(gthis, row, 500, 10, gthis.totalPaymentMethods.otherPayment)
                    row = await gthis.drawLine(gthis, row)
                }

                if (gthis.totalPaymentMethods.cancelRefund) {
                    row = await gthis.drawRowTextLeft(gthis, row, "Cancel/Refund")
                    gthis.drawTextRightCurrency(gthis, row, 500, 10, gthis.totalPaymentMethods.cancelRefund * -1)
                    row = await gthis.drawLine(gthis, row)
                }


                row = await gthis.getNextRow(gthis,row, 3)
                gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'NET SALES')
                gthis.drawTextRightCurrency(gthis, row, 570, 10, gthis.totalPaymentMethods.netSales)
                gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            }

            // Lottery Sales
            if (gthis.getSetting('accountantShowLottery')) {
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'LOTTERY SALES')
                gthis.drawColHeadRight(gthis, row, 500, 9, "Sales Amount")
                row = await gthis.getNextRow(gthis,row, 1)
                row = await gthis.drawRowTextLeft(gthis, row, "Online Sales")
                gthis.drawTextRightCurrency(gthis, row, 500, 10, gthis.lotterySales.onlineLotterySales)
                row = await gthis.drawLine(gthis, row)

                if (gthis.lotterySales.onlineLotteryCanceled == null) {
                    gthis.lotterySales.onlineLotteryCanceled=0
                }
                row = await gthis.drawRowTextLeft(gthis, row, "Online Cancel")
                gthis.drawTextRightCurrency(gthis, row, 500, 10, gthis.lotterySales.onlineLotteryCanceled * -1)
                row = await gthis.drawLine(gthis, row)

                if (gthis.lotterySales.onlineLotteryPaidOut == null) {
                    gthis.lotterySales.onlineLotteryPaidOut=0
                }
                row = await gthis.drawRowTextLeft(gthis, row, "Online Payout")
                gthis.drawTextRightCurrency(gthis, row, 500, 10, gthis.lotterySales.onlineLotteryPaidOut * -1)
                row = await gthis.drawLine(gthis, row)

                row = await gthis.drawRowTextLeft(gthis, row, "Instant Sales")
                gthis.drawTextRightCurrency(gthis, row, 500, 10, gthis.lotterySales.instantLotterySales)
                row = await gthis.drawLine(gthis, row)
                
                if (gthis.lotterySales.instantLotteryPaidOut == null) {
                    gthis.lotterySales.instantLotteryPaidOut=0
                }
                row = await gthis.drawRowTextLeft(gthis, row, "Instant Payout")
                gthis.drawTextRightCurrency(gthis, row, 500, 10, gthis.lotterySales.instantLotteryPaidOut * -1)
                row = await gthis.drawLine(gthis, row)

                var netLottery = gthis.lotterySales.onlineLotterySales
                                - gthis.lotterySales.onlineLotteryCanceled
                                - gthis.lotterySales.onlineLotteryPaidOut
                                + gthis.lotterySales.instantLotterySales
                                - gthis.lotterySales.instantLotteryPaidOut

                row = await gthis.getNextRow(gthis,row, 3)
                gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'NET LOTTERY')
                gthis.drawTextRightCurrency(gthis, row, 570, 10, netLottery)
                gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            }
            // End Lottery

            // Start Fuel Invoiced

            if (gthis.getSetting('accountantShowFuelInvoice')) {
                row = await gthis.getNextRow(gthis,row, 6)

                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'FUEL INVOICED')

                row = await gthis.getNextRow(gthis,row, 3)

                gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL FUEL INVOICED')

                var totalFuelInvoiced = 0
                if (gthis.totalFuelVolumePurchased) {
                    totalFuelInvoiced = gthis.totalFuelPurchase / gthis.totalFuelVolumePurchased
                }
                gthis.drawTextRightCurrency(gthis, row, 320, 10, totalFuelInvoiced)

                gthis.drawTextRight(gthis, row, 420, 10, gthis.totalFuelVolumePurchased.toLocaleString('en-US'))

                gthis.drawTextRightCurrency(gthis, row, 570, 10, gthis.totalFuelPurchase)

                gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            }


            // Start Fuel Delivery
            if (gthis.getSetting('accountantShowFuelDelivery')) {
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'FUEL DELIVERY')

                row = await gthis.getNextRow(gthis,row)
                // gthis.drawColHeadRight(gthis, row, 320, 9, "Cost/Gal")
                gthis.drawColHeadRight(gthis, row, 500, 9, "Gallons Delivered")
                // gthis.drawColHeadRight(gthis, row, 500, 9, "Amount")

                // var totalFuelSales = 0.00
                var totalFuelDeliveredVol = 0.00
                for (const fuel of gthis.fuelDeliveredByType) {
                    
                    row = await gthis.drawRowTextLeft(gthis, row, fuel.product[0].toUpperCase() + fuel.product.substr(1).toLowerCase())

                    // text =  (fuel.saleAmount / fuel.saleVolume).toFixed(2) 
                    // gthis.drawTextRightCurrency(gthis, row, 320, 9, text)

                    gthis.drawTextRight(gthis, row, 500, 10, fuel.deliverdVol.toLocaleString('en-US'))

                    // gthis.drawTextRightCurrency(gthis, row, 500, 10, fuel.saleAmount)
                    // totalFuelSales += fuel.saleAmount
                    totalFuelDeliveredVol += fuel.deliverdVol

                    row = await gthis.drawLine(gthis, row)
                }

                row = await gthis.getNextRow(gthis,row, 3)

                gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL FUEL DELIVERED')

                // text =  (totalFuelSales / totalFuelVol).toFixed(2) 
                // gthis.drawTextRightCurrency(gthis, row, 320, 9, text)

                gthis.drawTextRight(gthis, row, 570, 10, totalFuelDeliveredVol.toFixed(3) + " gal")

                // gthis.drawTextRightCurrency(gthis, row, 570, 10, totalFuelSales)
                gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            }
            // End fuel delivery

            // Start Inventory Purchase
            if (gthis.getSetting('accountantShowInventoryPurchase')) {
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'INVENTORY PURCHASE')

                row = await gthis.getNextRow(gthis,row)
                gthis.drawColHeadRight(gthis, row, 500, 9, "Cost")
                var totalInventoryPurchased = 0.00
                for (const inventory of gthis.inventoryPurchase) {
                    
                    row = await gthis.drawRowTextLeft(gthis, row, inventory.deptMapping[0].toUpperCase() + inventory.deptMapping.substr(1).toLowerCase())

                    gthis.drawTextRightCurrency(gthis, row, 500, 10, inventory.expenseCost)

                    totalInventoryPurchased += inventory.expenseCost

                    row = await gthis.drawLine(gthis, row)
                }

                row = await gthis.getNextRow(gthis,row, 3)

                gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL INVENTORY PURCHASED')

                gthis.drawTextRightCurrency(gthis, row, 570, 10, totalInventoryPurchased)

                gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            }

            // Create PDF Document
            const pdfDataUri = await gthis.pdfDoc.saveAsBase64({ dataUri: true });
            document.getElementById('pdf').src = pdfDataUri;
        },

        pageHeader: async(gthis, titleText)  => {

            if (!titleText) {
                titleText = "ACCOUNTANT SUMMARY"
            }
            gthis.pageNumber++

            gthis.page = gthis.pdfDoc.addPage(PageSizes.Letter);
            
            var storeName = UserStore.getters.getActiveStore.storeName
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(storeName, 14);

            gthis.page.drawText(storeName, { 
                x: 300 - (textWidth/2), 
                y: 750, 
                size: 14, 
                })

            var row = 725
            var reportHead = titleText
            textWidth = gthis.helveticaFont.widthOfTextAtSize(reportHead, 13);

            gthis.page.drawText(reportHead, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 15, 
                })            

            row = 700
            var dateRange = moment(gthis.dateRange[0]).format('MM/DD/YYYY') + " - " + moment(gthis.dateRange[1]).format('MM/DD/YYYY')
            textWidth = gthis.helveticaFont.widthOfTextAtSize(dateRange, 11);

            gthis.page.drawText(dateRange, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 11, 
                })
            await gthis.pageFooter(gthis)                         
                
        },

        veecliLogo: async(gthis) => {
            const pngUrl = '/images/veecli-logo.png'
            const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())

            const pngImage = await gthis.pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.08)
            
            gthis.page.drawImage(pngImage, {
                x: 300,
                y: 18,
                width: pngDims.width,
                height: pngDims.height,
                rotate: degrees(0),
                opacity: 0.75,
            })
            gthis.page.drawText(" VEECLi.com", { 
                x: 308, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })                    
        },

        pageFooter: async(gthis) => {

            let timeNow = Date()
            timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')

            gthis.page.drawText(timeNow, { 
                x: 20, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })

            var textWidth = gthis.helveticaFont.widthOfTextAtSize('Page ' + gthis.pageNumber, 9)
            gthis.page.drawText('Page ' + gthis.pageNumber, { 
                x: 590 - textWidth, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })
            await gthis.veecliLogo(gthis)
        },

        getNextRow: async(gthis,row, count) => {
  
            if (!count) count = 1
            if (row > 75) {
                return row - (5 * count)
            } else {
                await gthis.pageHeader(gthis)

                // Set Line to start records
                return 680
            }
            
        },

        drawRowHeadTextLeft: async (gthis, row, colStart, fontSize, text) => {
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize, 
            })
            return row
        },

        drawRowTextLeft: async (gthis, row, text) => {
            row = await gthis.getNextRow(gthis,row, 2)
            // text = text[0].toUpperCase() + text.substr(1).toLowerCase()
            if (text === "InsideSales") { text = "Inside Sales"}
            if (text === "BeerWine") { text = "Beer / Wine"}
            text = gthis.toTitleCase(text)

            gthis.page.drawText(text, { 
                x: 45, 
                y: row, 
                size: 9, 
            })
            return row
        },

        drawTextLeft: async (gthis, row, colStart, fontSize, text) => {
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRight: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawColHeadRight:async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row+3, 
                size: fontSize,
                TextAlignment: 2 
            })
            gthis.page.drawLine({
                start: { x: colStart - textWidth - 40, y: row },
                end: { x: colStart, y: row  },
                thickness: 1,
                opacity: 0.4,
            })
            
        },

        drawTextRightGal: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text+" gal.", fontSize)
            gthis.page.drawText(text+" gal.", { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRightCurrency: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(gthis.dollarUSLocale.format(text), fontSize)
            gthis.page.drawText(gthis.dollarUSLocale.format(text), { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },        

        drawLine: async (gthis, row) => {
            row = await gthis.getNextRow(gthis,row, 1)
            gthis.page.drawLine({
                start: { x: 45, y: row },
                end: { x: 500, y: row },
                thickness: 1,
                opacity: 0.2,
            })
            return row
        },

        drawTotalRectangle: async (gthis, row, colStart, width, height, opacity) => {
            gthis.page.drawRectangle({
                x: colStart-4,
                y: row-4,
                width: width,
                height: height,
                rotate: degrees(0),
                borderWidth: 0,
                borderColor: grayscale(0.5),
                color: rgb(0, 0, 0),
                opacity: opacity,
                borderOpacity: 0.75,
            })
        },

        async loadData() {
            this.isShowPDFDialogLocal = true
            this.createPdf(this)
            this.pageNumber = 0
        },
    },
  
}
</script>


<style scoped>

.popupDialog {
    /* position:absolute; */
    width:100%;
    height: 100%; 
    max-height: 1000px;
    min-height: 900px;     
}

.pdfiFrame {
    position:absolute; 
    top:0; 
    left:0; 
    bottom:0; 
    /* right:0;  */
    width:100%; 
    height:100%; 
    /* border:none; */
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    /* padding:0;  */
    overflow:hidden; 
    /* z-index:999999;    */
}
div{
    height: 100%;
}
</style>