<template>
    <div class="panel has-text-weight-semibold">
        <b-loading v-model="isLoading" >
            <b-icon
                pack="fas"
                icon="sync-alt"
                size="is-large"
                custom-class="fa-spin">
            </b-icon>                
        </b-loading>        
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-11 has-text-centered">ACCOUNTANT SUMMARY REPORT</div>
                <div class="column has-text-right is-1">
                    <b-button  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                        <i class="fas fa-print"></i>
                    </b-button>
                    <!-- <b-button class="is-dark is-small" @click="generatePDF">Print</b-button> -->
                    <!-- <b-button class="is-dark is-small is-hidden-mobile" @click="togglePDFDialog">Print</b-button> -->
                </div>                    
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <date-range-selector
                    :dateRange="salesDateRange"
                    @set-date-range="setDateRange"
                    >
                </date-range-selector>
            </div>
            <div class="column">

            </div>
        </div>

        <div>
            <div class="columns" v-if="showStoreName">
                <div class="column">
                    <div class="columns has-text-centered has-text-weight-bold">
                    <div class="column is-size-2 is-12">{{ getActiveStore.storeName }}</div>
                </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <div class="columns has-text-centered has-text-weight-bold">
                    <!-- <div class="column is-size-2 is-12">{{ monthName }} {{ month.getFullYear() }}</div> -->
                    <div class="column is-size-5 is-12">Period: {{ salesDateRange[0] | formatDate }} to {{ salesDateRange[1] | formatDate }}</div>
                </div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-1"></div>
                <div class="column is-10">
                    <div class="is-size-4 has-text-weight-bold has-text-centered has-background-grey-lighter">
                        ACCOUNTANT REPORT
                    </div>
                    <br>
                    <div class="panel">
                            <div class="panel-block" v-if="getSetting('accountantShowSales')">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Merchandise Sales
                                </div>
                            </div>
                            <div class="columns is-mobile bottom-bordered-row" v-bind:key="dept.storeMapping" v-for="dept in deptSales">
                                <div class="column is-6 has-text-left">
                                    {{dept.storeMapping}}
                                </div>
                                <div class="column is-hidden-mobile is-2 has-text-right">
                                    {{ (totalGrossRevenue ? (dept.netSalesValue / totalGrossRevenue * 100) : 0).toFixed(2) }}%
                                </div>
                                <div class="column is-hidden-mobile is-4 has-text-right">
                                    {{ dept.netSalesValue | formatCurrency}}
                                </div>

                                <!-- <div class="column is-2 has-text-right">
                                    {{ (dept.netSalesValue / totalGrossRevenue * 100).toFixed(2) }}%
                                </div> -->
                                <div class="column is-hidden-tablet is-6 has-text-right">
                                    {{ dept.netSalesValue | formatCurrency}}
                                </div>
                            </div>


                            <div class="columns is-mobile total-row">
                                <div class="column is-6 has-text-left">
                                    Total Merchandise Sales
                                </div>
                                <div class="column is-hidden-mobile is-2 has-text-right">
                                    {{ (totalGrossRevenue ? (totalMerchandiseRevenue / totalGrossRevenue * 100) : 0).toFixed(2) }}%
                                </div>
                                <div class="column is-hidden-mobile is-4 has-text-right">
                                    {{ totalMerchandiseRevenue | formatCurrency}}
                                </div>
                                <!-- Mobile Only -->
                                <div class="column is-hidden-tablet is-6 has-text-right">
                                    {{ totalMerchandiseRevenue | formatCurrency}}
                                </div>
                            </div> 
                            </section>
                            </div>
                            <br  v-if="getSetting('accountantShowSales')">
                            
                            <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Fuel Sales
                                </div>
                            </div>  
                            
                            <div class="is-size-7 is-hidden-mobile columns bottom-bordered-row">
                                <div class="column is-4 has-text-left">
                                    Fuel Types
                                </div>
                                <div class="column is-2 has-text-right">
                                    Average Price
                                </div> 
                                <div class="column is-3 has-text-right">
                                    Volume
                                </div>
                                                                
                                <div class="column is-3 has-text-right">
                                    Sales Amount
                                </div>
                            </div>

                            <div class="columns is-hidden-mobile bottom-bordered-row" v-bind:key="fuel.id" v-for="fuel in profitAndLossData.fuelSalesList">
                                <div class="column is-4 has-text-left">
                                    {{ fuel.product[0].toUpperCase() + fuel.product.substr(1).toLowerCase() }}
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ (fuel.saleVolume ? (fuel.saleAmount / fuel.saleVolume) : 0) | formatCurrency}}
                                </div>  
                                <div class="column is-3 has-text-right">
                                    <span class="is-hidden-touch">{{ fuel.saleVolume | formatFuelVolume }}</span>
                                    <span class="is-hidden-desktop">{{ fuel.saleVolume.toFixed(0) }}</span>
                                </div>                               
                                <div class="column is-3 has-text-right">
                                    {{ fuel.saleAmount | formatCurrency}}
                                </div>                               
                            </div>
                            <div class="columns is-hidden-mobile bottom-bordered-row total-row">
                                <div class="column is-5 has-text-left">
                                    Total Fuel Sales
                                </div>
                                <div class="column is-1 has-text-right">
                                    {{ (totalFuelVolume ? (totalFuelRevenue / totalFuelVolume) : 0)  | formatCurrency}}
                                </div> 
                                <div class="column is-3 has-text-right">
                                    <span class="is-hidden-touch">{{ totalFuelVolume | formatFuelVolume }}</span>
                                    <span class="is-hidden-desktop">{{ totalFuelVolume.toFixed(0) }}</span>
                                </div>                                
                                <div class="column is-3 has-text-right ">
                                    {{ totalFuelRevenue | formatCurrency}}
                                </div>
                            </div> 
                            
                            <!-- Mobile Only -->
                            <div class="is-size-7 columns is-hidden-tablet is-mobile bottom-bordered-row">
                                <div class="column is-5 has-text-left">
                                    Fuel Types
                                </div>
                                <div class="column is-3 has-text-right">
                                    Volume
                                </div>
                                                                
                                <div class="column is-4 has-text-right">
                                    Sales Amount
                                </div>
                            </div>

                            <div class="columns is-hidden-tablet is-mobile bottom-bordered-row" v-bind:key="fuel.product" v-for="fuel in profitAndLossData.fuelSalesList">
                                <div class="column is-5 has-text-left">
                                    {{ fuel.product[0].toUpperCase() + fuel.product.substr(1).toLowerCase() }}
                                </div>
                                <div class="column is-3 has-text-right">
                                    <span class="is-hidden-touch">{{ fuel.saleVolume | formatFuelVolume }}</span>
                                    <span class="is-hidden-desktop">{{ fuel.saleVolume.toFixed(0) }}</span>
                                </div>                               
                                <div class="column is-4 has-text-right">
                                    {{ fuel.saleAmount | formatCurrency}}
                                </div>                               
                            </div>
                            <div class="columns is-hidden-tablet is-mobile bottom-bordered-row total-row">
                                <div class="column is-5 has-text-left">
                                    Total Fuel Sales
                                </div> 
                                <div class="column is-3 has-text-right">
                                    <span class="is-hidden-touch">{{ totalFuelVolume | formatFuelVolume }}</span>
                                    <span class="is-hidden-desktop">{{ totalFuelVolume.toFixed(0) }}</span>
                                </div>                                
                                <div class="column is-4 has-text-right ">
                                    {{ totalFuelRevenue | formatCurrency}}
                                </div>
                            </div> 
                            </section>
                            </div>

                            <br>
        
                            <div class="panel-block">
                            <section class="control">
                            <div class="columns bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Merchandise Sales & Sales Tax
                                </div>
                            </div> 
                            
                            <div class="is-size-7 is-hidden-mobile columns bottom-bordered-row">
                                <div class="column is-4 has-text-left">
                                    Tax Rates
                                </div>
                                <div class="column is-2 has-text-right">
                                    Current Rate
                                </div> 
                                <div class="column is-2 has-text-right">
                                    Tax Collected
                                </div>
                                <div class="column is-2 has-text-right">
                                    Average Rate
                                </div>                                                                 
                                <div class="column is-2 has-text-right">
                                    Sales Amount
                                </div>
                            </div>

                            <div class="columns is-hidden-mobile bottom-bordered-row" v-bind:key="tax.id" v-for="tax in profitAndLossData.taxInfoList">
                                <div class="column is-4 has-text-left">
                                    <span class="is-capitalized">{{ tax.name.toLowerCase() }}</span>
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ tax.taxRate.toFixed(2) }}%
                                </div>                                 
                                <div class="column is-2 has-text-right">
                                    {{ tax.netTax | formatCurrency}}
                                </div>  
                                <div class="column is-2 has-text-right">
                                    {{ ((tax.taxableSales + tax.taxableRefunds) ? (tax.netTax * 100) / (tax.taxableSales + tax.taxableRefunds) : 0).toFixed(2) }}%
                                </div>                                                              
                                <div class="column is-2 has-text-right">
                                    {{ (tax.taxableSales + tax.taxableRefunds) | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-hidden-mobile bottom-bordered-row">
                                <div class="column is-6 has-text-left">
                                    No Tax
                                </div>
                                <div class="column is-2 has-text-right">
                                    $0.00
                                </div>
                                <div class="column is-2 has-text-right">
                                    0.00%
                                </div>                                                                 
                                <div class="column is-2 has-text-right">
                                    {{  totalNonTaxableSales | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-hidden-mobile bottom-bordered-row total-row">
                                <div class="column is-6 has-text-left">
                                    Merchandise Tax & Sales
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ salesTax.totalTaxes | formatCurrency}}
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ ( (totalMerchandiseRevenue - salesTax.totalTaxes) / totalMerchandiseRevenue * 10).toFixed(2) }}%
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ totalMerchandiseRevenue | formatCurrency}}
                                </div>
                            </div>

                            <!-- Mobile Only -->
                            <div class="is-size-7 is-mobile is-hidden-tablet columns bottom-bordered-row">
                                <div class="column is-4 has-text-left">
                                    Tax Rates
                                </div>
                                <div class="column is-4 has-text-right">
                                    Tax Collected
                                </div>
                                <div class="column is-4 has-text-right">
                                    Sales Amount
                                </div>
                            </div>
                            <div class="columns is-mobile is-hidden-tablet bottom-bordered-row" v-bind:key="tax.id" v-for="tax in profitAndLossData.taxInfoList">
                                <div class="column is-4 has-text-left">
                                    <span class="is-capitalized">{{ tax.name.toLowerCase() }}</span>
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ tax.netTax | formatCurrency}}
                                </div>  
                                <div class="column is-4 has-text-right">
                                    {{ (tax.taxableSales + tax.taxableRefunds) | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-mobile is-hidden-tablet bottom-bordered-row">
                                <div class="column is-6 has-text-left">
                                    No Tax
                                </div>                                                                
                                <div class="column is-6 has-text-right">
                                    {{  totalNonTaxableSales | formatCurrency }}
                                </div>
                            </div>
                            
                            <div class="columns is-mobile is-hidden-tablet bottom-bordered-row total-row">
                                <div class="column is-4 has-text-left">
                                    Tax & Sales
                                </div> 
                                <div class="column is-4 has-text-right">
                                    {{ salesTax.totalTaxes | formatCurrency}}
                                </div>                                                                
                                <div class="column is-4 has-text-right">
                                    {{ totalMerchandiseRevenue | formatCurrency}}
                                </div>
                            </div>
                            </section>
                            </div>
                            <br>

                            <div class="panel-block" v-if="getSetting('accountantShowPayment')">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Payment Details
                                </div>
                            </div>
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Credit Card
                                </div>
                   
                                <div class="column is-4 has-text-right">
                                    {{ this.totalSales.creditAmount | formatCurrency }}
                                </div>
                            </div>
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Debit Card
                                </div>
                   
                                <div class="column is-4 has-text-right">
                                    {{ this.totalSales.debitAmount | formatCurrency }}
                                </div>
                            </div>
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    EBT
                                </div>
                   
                                <div class="column is-4 has-text-right">
                                    {{ this.totalSales.ebtAmount | formatCurrency }}
                                </div>
                            </div>
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Cash
                                </div>
                   
                                <div class="column is-4 has-text-right">
                                    {{ this.totalSales.cashAmount | formatCurrency }}
                                </div>
                            </div> 
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Other
                                </div>
                   
                                <div class="column is-4 has-text-right">
                                    {{ this.totalSales.otherPayment | formatCurrency }}
                                </div>
                            </div>   
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Cancel/Refund
                                </div>
                   
                                <div class="column is-4 has-text-right">
                                    {{ (this.totalSales.cancelRefund) * -1 | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-mobile total-row">
                                <div class="column is-8 has-text-left">
                                    Net Sales
                                </div>
             
                                <div class="column is-4 has-text-right">
                                    {{ this.totalSales.netSales | formatCurrency }}
                                </div>
                            </div>
                                                                                                                                        
                            </section>
                            </div>
                            <br  v-if="getSetting('accountantShowPayment')">

                            <!-- <div class="panel-block"> -->
                            <div class="panel-block" v-if="getSetting('accountantShowLottery')">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Lottery
                                </div>
                            </div>                                  
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Online Sales  
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ lotterySales.onlineLotterySales | formatCurrency}}
                                </div>                            
                            </div>
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Online Canceled 
                                </div>
                                <div class="column is-4 has-text-right" :class="{'has-text-danger': lotterySales.onlineLotteryCanceled > 0.00 }">
                                    {{ lotterySales.onlineLotteryCanceled ? lotterySales.onlineLotteryCanceled * -1 : 0| formatCurrency}}
                                </div>                            
                            </div>                            
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Online Payouts 
                                </div>
                                <div class="column is-4 has-text-right" :class="{'has-text-danger': lotterySales.onlineLotteryPaidOut > 0.00 }">
                                  {{ lotterySales.onlineLotteryPaidOut ? lotterySales.onlineLotteryPaidOut * -1 : 0| formatCurrency}}
                                </div>                            
                            </div>

                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Instant Sales 
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ lotterySales.instantLotterySales | formatCurrency}}
                                </div>                            
                            </div>
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Instant Payouts 
                                </div>
                                <div class="column is-4 has-text-right" :class="{'has-text-danger': lotterySales.instantLotteryPaidOut > 0.00 }">
                                    {{ lotterySales.instantLotteryPaidOut ? lotterySales.instantLotteryPaidOut * -1: 0| formatCurrency}}
                                </div>                            
                            </div>
                            <div class="columns is-mobile total-row">
                                <div class="column is-8 has-text-left">
                                    Net Lottery
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ netLottery | formatCurrency }}
                                </div>
                            </div>    
                            </section>
                            </div>
                            <br  v-if="getSetting('accountantShowLottery')">                          

                        <div class="panel-block" v-if="getSetting('accountantShowFuelInvoice')">
                        <section class="control">
                        <div class="columns is-mobile bottom-bordered-row total-row">
                            <div class="column has-text-centered">
                                Fuel Invoice
                            </div>
                        </div>                          
                        <div class="columns is-hidden-mobile bottom-bordered-row">
                            <div class="column is-6 has-text-left">
                                Total Fuel Invoiced
                            </div>

                            <div class="column is-3 has-text-right">
                                {{ totalFuelVolumePurchased | formatFuelVolume }}
                            </div>
                            <div class="column is-3 has-text-right">
                                {{ totalFuelPurchase | formatCurrency }}
                            </div>
                        </div> 
                        
                        <div class="columns is-hidden-tablet is-mobile bottom-bordered-row">
                            <div class="column is-6 has-text-left">
                                Total Fuel Invoiced
                            </div>

                            <div class="column is-2 has-text-right">
                                <span class="">{{ totalFuelVolumePurchased.toFixed(0) }}</span>
                            </div>
                            <div class="column is-4 has-text-right">
                                {{ totalFuelPurchase | formatCurrency }}
                            </div>
                        </div> 
                        </section>
                        </div>
                        
                        <br v-if="getSetting('accountantShowFuelInvoice')">

                        <div class="panel-block" v-if="getSetting('accountantShowFuelDelivery')">
                            <section class="control">
                                <div class="columns is-mobile bottom-bordered-row total-row">
                                    <div class="column has-text-centered">
                                        Fuel Delivery
                                    </div>
                                </div> 
                                <div class="columns is-mobile bottom-bordered-row" v-bind:key="fuel.product" v-for="fuel in fuelDeliveredByType">
                                    <div class="column is-6 has-text-left">
                                        {{ fuel.product[0].toUpperCase() + fuel.product.substr(1).toLowerCase() }}
                                    </div>
                                    <div class="column is-6 has-text-right">
                                        {{ fuel.deliverdVol | formatFuelVolume }}
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row total-row">
                                    <div class="column is-6 has-text-left">
                                        Total Delivered
                                    </div>
                                    <div class="column is-6 has-text-right">
                                        {{ totalFuelVolumeDelivered | formatFuelVolume }}
                                    </div>
                                </div>                                                                
                            </section>
                        </div>

                       <br v-if="getSetting('accountantShowFuelDelivery')"> 

                        <div class="panel-block" v-if="getSetting('accountantShowInventoryPurchase')">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Inventory Purchase
                                </div>
                            </div> 
                            <div class="columns is-mobile bottom-bordered-row" v-bind:key="inventory.deptMapping" v-for="inventory in inventoryPurchase">
                                <div class="column is-8 has-text-left">
                                    {{ inventory.deptMapping[0].toUpperCase() + inventory.deptMapping.substr(1).toLowerCase() }} Purchased
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ inventory.expenseCost | formatCurrency}}
                                </div> 
                            </div>
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column is-8 has-text-left">
                                    Total Inventory Purchased
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ totalInventoryPurchased | formatCurrency }}
                                </div>
                            </div>                            

                            </section>
                        </div>
                        
                    </div>
                </div>
                <div class="column is-1"></div>
            </div>
            <accountant-summary-pdf
                ref="pdfReport"
                :isShowPDFDialog="isShowPDFDialog"
                :dateRange="salesDateRange"
                :profitAndLossData="profitAndLossData"
                :summaryNumbers="summaryNumbers"
                :fuelDeliveredByType="fuelDeliveredByType"
                :inventoryPurchase="inventoryPurchase"
                :totalFuelPurchase="totalFuelPurchase"
                :totalPaymentMethods="totalSales"
                :lotterySales="lotterySales"
                :deptSales="deptSales"
                :totalFuelVolumePurchased="totalFuelVolumePurchased"
                @close-pdf-dialog="togglePDFDialog"
            >
            </accountant-summary-pdf>
        </div>
    </div>
    
</template>

<script>

import UserStore from "../../../store/UserStore"
import moment from 'moment'
import axios from 'axios'
import DateRangeSelector from '../../../components/app/DateRangeSelector'
// import jspdf from "jspdf"
// import AccountantSummaryPDF from './AccountantSummaryPDF'
import AccountantSummaryPdf from './AccountantSummaryPDF.vue'
export default {

    components: {
        DateRangeSelector,
        AccountantSummaryPdf
    },

    metaInfo: {
        title: 'Accountant Summary'
    },

    data() {
        return {
            showStoreName: false,
            isShowPDFDialog: false,
            isLoading: true,
            isFullPage: true,
            salesDateRange: [],
            user: [],
            endDate: new Date(),
            minDate: new Date(2016, 8, 1),
            maxDate: new Date(),
            startDate: new Date(),
            month: new Date(),
            deptMappingsRevenue: [],
            profitAndLossData: {totalFuelVolumePurchased: 0.00},
            summaryNumbers: {},
            totalFuelRevenue: 0.00,
            totalFuelCost: 0.00,
            totalFuelVolume: 0.00,
            totalMerchandiseRevenue: 0.00,
            totalOtherIncome: 0.00,
            totalGrossRevenue: 0.00,
            totalFuelProfit: 0.00,
            totalMerchandiseCost: 0.00,
            totalMerchandiseProfit: 0.00,
            totalCostOfGoodsSold: 0.00,
            totalProfit: 0.00,
            totalOperatingExpense: 0.00,
            fuelProfitPerGallon: 0.00,
            fuelAverageCost: 0.00,
            totalInventoryPurchase: 0.00,
            fuelPurchase: 0.00,
            totalFuelPurchase: 0.00,
            inventoryPurchase: 0.00,
            totalFuelVolumePurchased: 0.00,
            lotterySales: {},
            salesTax: {},
            deptSales: [],
            otherTaxes: [],
            fuelDelivered: [],
            fuelDeliveredByType: [],
            totalSales: {},
        }
    },

    methods: {

        fetchData() {

            if (UserStore.getters.getActiveStore) {
                this.isLoading = true
                // const loadingComponent = this.$buefy.loading.open({
                //     container: this.isFullPage ? null : this.$refs.element.$el
                // })                

                this.resetMonthData()

                this.profitAndLossData = []

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/sales/profit?size=50" + "&fromDate="+this.salesDateRange[0].toISOString().split('T')[0]+"&toDate="+this.salesDateRange[1].toISOString().split('T')[0]

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.profitAndLossData = response.data.data
                        this.getDeptMappingsRevenue()
                        this.fetchFuelDelivery()
                        this.fetchStoreSales()
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        if (err.response.status === 404 || err.response.status === 400) {
                            this.$buefy.toast.open({
                                message: 'No shift data found',
                                type: 'is-warning'
                            })
                        }
                        this.isLoading = false
                    })
            }
        },

        fetchFuelDelivery() {
            
            if (UserStore.getters.getActiveStore) {
                
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + UserStore.getters.getActiveStore.storeId
                            + '/tanks/deliveries'  + "?start_date="+this.salesDateRange[0].toISOString().split('T')[0]+"&end_date="+this.salesDateRange[1].toISOString().split('T')[0]
                            // + '/tanks/deliveries?page=1&size=50'
                
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.fuelDelivered = response.data.data
                        
                        this.groupedDeliveryByFuel()
                        
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }

        },

        groupedDeliveryByFuel() {

            var result = []
            this.fuelDelivered.reduce(function(res, value) {
                if (!res[value.product]) {
                    res[value.product] = { product: value.product, deliverdVol: 0 }
                    result.push(res[value.product])
                }
                res[value.product].deliverdVol += (value.endingFuelVolume - value.startingFuelVolume)
                return res
            }, {})

            this.fuelDeliveredByType = result

        },

        getDeptMappingsRevenue() {

            this.deptMappingsRevenue = this.profitAndLossData.deptRevenueAndCostList.filter((dept) => {
                return dept.storeMapping !== 'Taxes'
            })

            this.otherTaxes = this.profitAndLossData.deptRevenueAndCostList.filter((dept) => {
                return dept.storeMapping == 'Taxes'
            })
            
            this.fuelPurchase = this.profitAndLossData.inventoryExpenseList.filter((inventory) => {
                return inventory.deptMapping === 'Fuel'
            })

            this.inventoryPurchase = this.profitAndLossData.inventoryExpenseList.filter((inventory) => {
                return inventory.deptMapping !== 'Fuel'
            })

            this.totalInventoryPurchase = 0.00

            this.inventoryPurchase.forEach(item => {
                this.totalInventoryPurchase += item.expenseCost
            })
            
            this.totalFuelPurchase = 0.00
            this.fuelPurchase.forEach(item => {
                this.totalFuelPurchase += item.expenseCost
            })

            this.deptMappingsRevenue.forEach(mapping => {
                if (mapping.storeMapping === "Taxes") {
                    return
                }
                if (mapping.storeMapping == null | mapping.storeMapping == "") {
                    mapping.storeMapping = "Other"
                }
                var map = this.deptSales.find((map) => map.storeMapping === mapping.storeMapping)
                if (map) {
                        map.netSalesValue += mapping.netSalesValue,
                        map.cost += mapping.cost,
                        map.profit += mapping.profit 
                } else {
                    this.deptSales.push({
                        "storeMapping": mapping.storeMapping,
                        "netSalesValue": mapping.netSalesValue,
                        "cost": mapping.cost,
                        "profit": mapping.profit                    
                        })
                }

                this.totalMerchandiseRevenue += mapping.netSalesValue
                this.totalMerchandiseCost += mapping.cost
                this.totalMerchandiseProfit += mapping.profit
            })

            this.profitAndLossData.expensesList.forEach(expense => {
                this.totalOperatingExpense += expense.amount
            })
            this.profitAndLossData.fuelSalesList.forEach(fuel => {
                this.totalFuelRevenue += fuel.saleAmount
                this.totalFuelVolume += fuel.saleVolume
            })

            this.profitAndLossData.additionalIncomeList.forEach(income => {
                this.totalOtherIncome += income.amount
            })

            this.totalFuelVolumePurchased = this.profitAndLossData.totalFuelVolumePurchased

            if (this.totalFuelVolumePurchased != 0.00) {
                this.fuelAverageCost = Math.round(this.totalFuelPurchase / this.totalFuelVolumePurchased * 100) / 100
            } else {
                this.fuelAverageCost = 0.00
            }
            
            this.totalGrossRevenue = this.totalFuelRevenue + this.totalMerchandiseRevenue + this.totalOtherIncome

            this.totalCostOfGoodsSold = this.totalMerchandiseCost + this.totalFuelCost + (this.totalFuelVolume * this.fuelAverageCost)
            this.lotterySales = this.profitAndLossData.lottery
            this.salesTax = this.profitAndLossData.salesTax
        },

        resetMonthData() {
            this.deptMappingsRevenue = []
            this.otherTaxes = []
            this.deptSales = []
            this.profitAndLossData = []
            this.lotterySales = {}
            this.salesTax = {}
            this.totalFuelRevenue = 0.00
            this.totalMerchandiseRevenue = 0.00
            this.totalGrossRevenue = 0.00
            this.totalFuelCost = 0.00
            this.totalFuelProfit = 0.00
            this.totalFuelVolume = 0.00
            this.totalMerchandiseCost = 0.00
            this.totalMerchandiseProfit = 0.00
            this.totalCostOfGoodsSold = 0.00
            this.totalProfit = 0.00
            this.totalOperatingExpense = 0.00
            this.totalOtherIncome = 0.00
            this.fuelProfitPerGallon = 0.00
            this.fuelAverageCost = 0.00
            this.totalInventoryPurchase = 0.00
            this.fuelPurchase = 0.00
            this.totalFuelPurchase = 0.00
            this.inventoryPurchase = 0.00
            this.totalFuelVolumePurchased = 0.00
        },

       fetchStoreSales() {
            if (UserStore.getters.getActiveStore) {

                this.totalSales.netSales = 0.00
                this.dailySales = []
                this.isLoading = true
                this.dataSales = []
                this.totalSales = []

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + UserStore.getters.getActiveStore.storeId
                    + '/sales?size=50'  
                    + "&fromDate="+this.salesDateRange[0].toISOString().split('T')[0]
                    + "&toDate="+this.salesDateRange[1].toISOString().split('T')[0]

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        this.dailySales = response.data.data
                        this.totalSales.creditAmount = this.dailySales.reduce((accumulator, object) => {
                                                            return accumulator + object.netSalesDTO.creditAmount;
                                                        }, 0)

                        this.totalSales.debitAmount = this.dailySales.reduce((accumulator, object) => {
                                                            return accumulator + object.netSalesDTO.debitAmount;
                                                        }, 0)

                        this.totalSales.cashAmount = this.dailySales.reduce((accumulator, object) => {
                                                            return accumulator + object.netSalesDTO.cashAmount;
                                                        }, 0)
                                                        
                        this.totalSales.ebtAmount = this.dailySales.reduce((accumulator, object) => {
                                                            return accumulator + object.netSalesDTO.linkCardAmount + object.netSalesDTO.lineCardAmount + object.netSalesDTO.foodstampAmount;
                                                        }, 0)                                                        


                        this.totalSales.cancelRefund = this.dailySales.reduce((accumulator, object) => {
                                                            return accumulator + object.netSalesDTO.cancelRefund;
                                                        }, 0)


                        this.totalSales.netSales = this.dailySales.reduce((accumulator, object) => {
                                                            return accumulator + object.netSalesDTO.netSales;
                                                        }, 0)
                                                        
                        this.totalSales.otherPayment = this.totalSales.netSales 
                                                    - this.totalSales.creditAmount 
                                                    - this.totalSales.debitAmount
                                                    - this.totalSales.cashAmount
                                                    - this.totalSales.ebtAmount
                                                    + this.totalSales.cancelRefund                                                        

                    })
                    .catch( (err) => {
                        this.isLoading = false
                        if (err.response) {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }                            
                        } else {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err)

                            if (err.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                        }
                        
                        this.isLoading = false

                    })
            }
            
        },        


        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },
        
    },

    watch: {
        salesDateRange: function() {
            this.fetchData()
        }

    },    

    computed: {

        monthName() {
            return moment(this.month).format('MMMM')
        },

        getActiveStore() {

            return UserStore.getters.getActiveStore
        },

        totalFuelVolumeDelivered() {

            return this.fuelDeliveredByType.reduce((a, b) => {
                return a + b["deliverdVol"];
            }, 0);

        },

        totalInventoryPurchased() {
            
            if (this.inventoryPurchase.length > 0) {
                return this.inventoryPurchase.reduce((a, b) => {
                            return a + b["expenseCost"];
                        }, 0);            
            } else {
                return 0
            }
        },
        
        totalNonTaxableSales() {
            if (this.profitAndLossData.taxInfoList === undefined) {
                return 0
            }
            var totalTaxableSales = this.profitAndLossData.taxInfoList.reduce((a, b) => {
                return a + b["taxableSales"] + b["taxableRefunds"];
            }, 0);
            return this.totalMerchandiseRevenue - totalTaxableSales
        },

        netLottery() {
            return (this.lotterySales.onlineLotterySales ? this.lotterySales.onlineLotterySales : 0)
                - (this.lotterySales.onlineLotteryCanceled ? this.lotterySales.onlineLotteryCanceled : 0)
                - (this.lotterySales.onlineLotteryPaidOut ? this.lotterySales.onlineLotteryPaidOut : 0)
                + (this.lotterySales.instantLotterySales ? this.lotterySales.instantLotterySales : 0)
                - (this.lotterySales.instantLotteryPaidOut ? this.lotterySales.instantLotteryPaidOut : 0)
        }

    },

    mounted() {
        this.startDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), 1)
        this.salesDateRange=[this.startDate, this.endDate]
    }    
}
</script>

<style>

.bottom-bordered-row {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;
    height: 2em;
}

.bottom-bordered-row2 {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;

}

.total-row {
    background-color:  rgb(230, 230, 230);
    font-weight: 1000;
}

</style>